<template>
  <div class="pay">
    <titles></titles>
    <div class="banner">
      <div class="banner_text">
        <div class="banner_text_box">
          <h3>薪酬外包服务</h3>
          <p>全国400+城市属地计薪/发放/报税一站式服务</p>
          <p>薪酬社保个税 一体化定制服务</p>
          <div class="banner_btn"><a href="https://affim.baidu.com/unique_28438292/chat?siteId=13950180&userId=28438292&siteToken=22dd0d31e1f7a80559410d71206df7db" target="_blank">立即咨询</a></div>
        </div>
      </div>
      <img src="@/assets/pay_banner.jpg" />
    </div>

    <div class="pay_one">
      <div class="all_box">
        <div class="pay_one_title">
          <textTitle
            :cnTitle="txt.oneCnTitle"
            :enTitle="txt.oneEnTitle"
          ></textTitle>
        </div>
        <div class="pay_one_box">
          <div class="pay_one_body">
            <img src="@/assets/payImg.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="henfu_po">
      <henfu></henfu>
    </div>
    <div class="pay_two">
      <div class="all_box">
        <div class="pay_two_box">
          <div class="pay_two_title">
            <textTitle
              :cnTitle="txt.twoCnTitle"
              :enTitle="txt.twoEnTitle"
            ></textTitle>
          </div>
          <div class="pay_two_list">
            <div class="pay_two_item">
              <div class="pay_two_item_top">
                <div class="pay_two_item_top_left">
                  <img src="@/assets/pay_icon01.png" />
                </div>
                <div class="pay_two_item_top_right">
                  <h3>智能算薪算税</h3>
                  <p>全系统化薪税计算</p>
                </div>
              </div>
              <div class="pay_two_item_bottom">
                <ul>
                  <li>1 . 智能发薪系统</li>
                  <li>2 . 数据智能计算</li>
                  <li>3 . 以工资算税</li>
                  <li>4 . 以税算工资</li>
                  <li>5 . 多主体合并算税</li>
                </ul>
              </div>
            </div>
            <div class="pay_two_item">
              <div class="pay_two_item_top">
                <div class="pay_two_item_top_left">
                  <img src="@/assets/pay_icon02.png" />
                </div>
                <div class="pay_two_item_top_right">
                  <h3>发薪及报税</h3>
                  <p>全国多城市属地化发薪报税</p>
                </div>
              </div>
              <div class="pay_two_item_bottom">
                <ul>
                  <li>1 . 支持一月多发</li>
                  <li>2 . 支持多类收入快发</li>
                  <li>3 . 全系统数据流转</li>
                  <li>4 . 支持合并申报</li>
                  <li>5 . 支持多属地申报</li>
                </ul>
              </div>
            </div>
            <div class="pay_two_item">
              <div class="pay_two_item_top">
                <div class="pay_two_item_top_left">
                  <img src="@/assets/pay_icon03.png" />
                </div>
                <div class="pay_two_item_top_right">
                  <h3>薪税结构筹划</h3>
                  <p>合理规划员工薪筹结构</p>
                </div>
              </div>
              <div class="pay_two_item_bottom">
                <ul>
                  <li>1 . 员工薪酬结构评估</li>
                  <li>2 . 工资社保组成分析</li>
                  <li>3 . 激励效能评估</li>
                  <li>4 . 薪税结构解决方案</li>
                  <li>5 . 员工满意度跟踪</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <check :type="2" @gotoContact="gotoContact($event)"></check>
    <foots @navChange = "navChange"></foots>
  </div>
</template>
<script>
import check from "@/components/check.vue";
import textTitle from "@/components/textTitle.vue";
import titles from "@/components/titles.vue";
import foots from "@/components/foots.vue";
import henfu from "@/components/henfu.vue";
export default {
  name: "Pay",
  components: {
    titles,
    foots,
    textTitle,
    henfu,
    check,
  },
  data() {
    return {
      txt: {
        oneCnTitle: "邦芒专业发薪报税服务方案",
        oneEnTitle: "Payroll tax service",
        twoCnTitle: "薪酬外包的服务项目",
        twoEnTitle: "Service Items",
      },
    };
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  methods: {
    //跳转到contact 并查询
    gotoContact(e) {
      this.$router.push({
        path: "/contact",
        query: {
          sCode: e.sCode,
          qCode: e.qCode,
          compname: e.compname,
        },
      });
    },
    navChange(){
      window.scrollTo(0, 0);
    }
  },
};
</script>
<style lang="scss" scoped>
.banner_btn {
  a{
    color: #fff;
    display: block;
    text-decoration: none;
  }
  font-size: 16px;
  width: 140px;
  height: 40px;
  border-radius: 15px;
  text-align: center;
  color: #fff;
  background: #d70035;
  line-height: 40px;
  margin-top: 90px;
}
.banner {
  position: relative;
  .banner_text {
    position: absolute;
    width: 100%;
    text-align: left;
    top: 50%;
    margin-top: -100px;
  }
  .banner_text_box {
    width: 80%;
    margin: 0 auto;
    h3 {
      font-size: 55px;
      color: #fff;
      margin: 0;
      margin-bottom: 32px;
    }
    p {
      font-size: 24px;
      color: #fff;
      margin: 0;
      margin: 12px 0;
      span {
        color: #d70035;
      }
    }
  }
}

.pay_one_title {
  padding: 100px 0;
}
.pay_one {
  background: #f2f2f2;
}
.pay_one_box {
  position: relative;
  height: 500px;
}
.pay_one_body {
  position: absolute;
}
.pay_two_title {
  padding: 100px 0;
}
.pay_two_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .pay_two_item {
    width: 31%;
    .pay_two_item_top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background: #758bc5;
      padding: 30px 0;
      .pay_two_item_top_left {
        width: 34%;
        text-align: right;
        img {
          margin-top: 15px;
        }
      }
      .pay_two_item_top_right {
        width: 64%;
        text-align: left;
        color: #fff;
        h3 {
          margin: 0;
          font-size: 36px;
          margin-bottom: 4px;
        }
        p {
          margin: 0;
        }
      }
    }
    .pay_two_item_bottom {
      background: #f2f2f2;
      padding: 50px 90px;
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: left;
        li {
          font-size: 16px;
          color: #333333;
          margin: 15px 0;
        }
      }
    }
  }
}
.pay_two {
  padding-bottom: 140px;
}
</style>                                                     